import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    // There is a bug in using the key as the cart does not return inventory channel keys.
    // test
    '875f8689-4b2f-432b-b46c-637bd388d7dd': {
      expectedDeliveryDays: {
        onStock: '36-72 horas',
        outOfStock: '5-8 días',
      },
    },
    // acc
    '0555f109-33db-4dd4-ae59-ede790e93016': {
      expectedDeliveryDays: {
        onStock: '36-72 horas',
        outOfStock: '5-8 días',
      },
    },
    // prod
    'caf3428f-ea09-4c73-83aa-01d35af642a8': {
      expectedDeliveryDays: {
        onStock: '36-72 horas',
        outOfStock: '5-8 días',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '5-8 días',
  },
  nextDayDeliveryTimeLimit: '17:30',
})
